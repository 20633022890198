import { InvoiceData, Office, USER_ORIGIN, UserProcess } from '@interfaces';
import { Company } from './company';
import { Language } from './language';
import { Role } from './role';

export interface LoginPayload {
  email: string;
  password: string;
  userLangId: Language['id'];
}
export interface LoginResponsePayload {
  expp: number;
}
export interface LoginErrorPayload {
  errorMessage: string;
}

export interface ActivateAccountPayload {
  password: string;
  passwordConfirmation: string;
  activation_token: string;
  pin?: string;
}

export interface UpdateMyselfPayload {
  languageId: string;
}

export interface UpdateAdminselfPayload {
  email?: string;
  languageId?: string;
}

export interface User {
  id?: string;
  name: string;
  email: string;
  userOrigin: USER_ORIGIN;
  languageId: string;
  companyId: string;
  roleId: string;
  telephoneNumber: string | null;
  isActive?: boolean;
  language?: Language;
  company?: Company;
  role?: Role;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  activatedAt?: string;
  lastLoginAt?: string;
  accountCreatedByEmployeeId: string | null;
  accountCreatedByEmployee: Partial<User>; // id, name, language, company
  userProcesses: UserProcess[];
  allowedToAddOwnUserProcesses: boolean;
  userProcessesLimit: number;
  officeId: string | null;
  office?: Office | null;
  supportedUserOrigins?: USER_ORIGIN[] | null;
  avatarUpdatedAt: string;
  invoiceData?: InvoiceData | null;
}

export interface SetNewPasswordPayload {
  password_reset_token: string;
  password: string;
  passwordConfirmation: string;
}
