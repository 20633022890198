<div class="page-container">
  <!-- LEFT COLUMN -->
  <div class="column left" *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP'">
    <div class="texts-container">
      <h1 class="main-title-text" [innerHTML]="'LOGIN_PAGE.MAIN_TITLE' | translate: {}"></h1>
      <h3 class="subtitle-text" [innerHTML]="'LOGIN_PAGE.SUBTITLE' | translate: {}"></h3>
    </div>
  </div>

  <!-- RIGHT COLUMN -->
  <ng-container *ngIf="APIAvailable">
    <div class="column right" *ngIf="currentUserLang?.key; else userLangLoader">
      <div class="utils-buttons-wrapper">
        <app-utils-buttons (userLangChanged)="userLangChanged($event)"></app-utils-buttons>
      </div>

      <!-- FORM -->
      <div class="content-column">
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <div class="graphic-dot"></div>
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <div class="form-title">{{ 'LOGIN_PAGE.SIGN_IN' | translate }}</div>
          </div>
        </div>

        <!-- FORM -->
        <form nz-form [formGroup]="loginForm" class="login-form" name="loginForm">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired nzFor="loginEmail">
              {{ 'LABELS.EMAIL' | translate }}
            </nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input
                nz-input
                id="loginEmail"
                name="loginEmail"
                autocomplete="loginEmail"
                type="text"
                formControlName="email"
                placeholder="{{ 'LOGIN_PAGE.ENTER_EMAIL' | translate }}"
                required
                autofocus />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired nzFor="loginPassword">
              {{ 'LOGIN_PAGE.PASSWORD' | translate }}
            </nz-form-label>
            <nz-form-control [nzSpan]="24">
              <nz-input-group [nzSuffix]="suffixTemplate">
                <input
                  nz-input
                  id="loginPassword"
                  name="loginPassword"
                  autocomplete="loginPassword"
                  [type]="isPasswordVisible ? 'text' : 'password'"
                  formControlName="password"
                  placeholder="{{ 'LOGIN_PAGE.ENTER_PASSWORD' | translate }}"
                  required />
              </nz-input-group>
              <ng-template #suffixTemplate>
                <i
                  nz-icon
                  [nzType]="isPasswordVisible ? 'eye-invisible' : 'eye'"
                  (click)="isPasswordVisible = !isPasswordVisible"></i>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <!-- Submit button -->
          <div nz-row *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP' || (loading$ | async) === false">
            <div nz-col [nzSpan]="24">
              <button
                (click)="submitLoginForm()"
                class="submit-button"
                [disabled]="loginForm.pristine || (loginForm.dirty && loginForm.invalid) || (loading$ | async)"
                mat-raised-button
                color="primary">
                {{ 'LOGIN_PAGE.SIGN_IN' | translate }}
              </button>
            </div>
          </div>

          <!-- Mobile spinner -->
          <div class="loading-container" *ngIf="(loading$ | async) && deviceInfo.deviceTypeDetected !== 'DESKTOP'">
            <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
          </div>

          <!-- Errors -->
          <div class="error-message-container" *ngIf="errorMessage$ | async as errorMessage">
            <p>{{ 'LOGIN_PAGE.' + errorMessage | translate }}</p>
          </div>

          <!-- Reset password link -->
          <div nz-row *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP' || (loading$ | async) === false">
            <div nz-col [nzSpan]="24">
              <div class="reset-password-link">
                <a (click)="$event.preventDefault()" [routerLink]="['/reset-password']">
                  {{ 'AUTH.RESET-PASSWORD' | translate }}
                </a>
              </div>
            </div>
          </div>
        </form>

        <!-- Desktop spinner -->
        <div class="loading-container" *ngIf="(loading$ | async) && deviceInfo.deviceTypeDetected === 'DESKTOP'">
          <mat-spinner [diameter]="35"></mat-spinner>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!APIAvailable">
    <div class="column right extra-space">
      <div class="utils-buttons-wrapper">
        <app-utils-buttons (userLangChanged)="userLangChanged($event)"></app-utils-buttons>
      </div>
      <div class="content-column extra-space">
        <div class="form-title error-title">
          {{ 'NT.NO_CONNECTION_WITH_SERVER' | translate }}
        </div>
        <div class="connection-error-block">
          <div class="subtitle" [innerHTML]="'NT.CONNECTION_ERROR_EXPLANATION' | translate"></div>
          <ul>
            <li [innerHTML]="'NT.WAIT_WE_WILL_TRY_TO_RECONNECT' | translate"></li>
            <li [innerHTML]="'NT.CHECK_INTERNET_CONNECTION' | translate"></li>
            <li [innerHTML]="'NT.CHECK_FIREWALL_SETTINGS' | translate"></li>
            <li [innerHTML]="'NT.CONSULT_LOCAL_NETWORK_ADMIN' | translate"></li>
            <li [innerHTML]="'NT.COMEBACK_LATER' | translate"></li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #userLangLoader>
  <div class="column right">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</ng-template>

<div
  *ngIf="safeLogoutInfo && currentUserLang?.key"
  (click)="safeLogoutInfo = false; $event.stopPropagation(); $event.preventDefault()"
  class="safe-logout-info">
  <h1 [innerHTML]="'AUTH.YOU-HAVE-BEEN-SAFELY-LOGGED-OUT' | translate"></h1>
</div>

<app-cookie-banner></app-cookie-banner>
