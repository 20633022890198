import { Injectable } from '@angular/core';
import { CreateOfficeOpts, SearchOfficeOpts, ToggleAssignedVoivodeship } from '@interfaces';
import { Actions, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { AppState } from '@state';
import * as officeActions from './office.actions';
import * as officeSelectors from './office.selectors';

@Injectable()
export class OfficeFacade {
  public offices$ = this.store.select(officeSelectors.selectOffices);
  public loadedOffice$ = this.store.select(officeSelectors.selectLoadedOffice);
  public pagination$ = this.store.select(officeSelectors.selectPagination);
  public loading$ = this.store.select(officeSelectors.selectLoading);
  public errorMessage$ = this.store.select(officeSelectors.selectErrorMessage);

  public updateOfficeSuccess$ = this.actions.pipe(ofType(officeActions.updateOfficeSuccess));
  public udpateOfficeError$ = this.actions.pipe(ofType(officeActions.updateOfficeError));
  public createOfficeSuccess$ = this.actions.pipe(ofType(officeActions.createOfficeSuccess));
  public removeOfficeSuccess$ = this.actions.pipe(ofType(officeActions.removeOfficeSuccess));
  public restoreOfficeSuccess$ = this.actions.pipe(ofType(officeActions.restoreOfficeSuccess));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public searchOffices(opts: SearchOfficeOpts): void {
    this.store.dispatch(officeActions.searchOffices({ opts }));
  }

  public getOffice(officeId: string): void {
    this.store.dispatch(officeActions.getOffice({ officeId }));
  }

  public createOffice(opts: CreateOfficeOpts): void {
    this.store.dispatch(officeActions.createOffice({ opts }));
  }

  public updateOffice(officeId: string, opts: CreateOfficeOpts): void {
    this.store.dispatch(officeActions.updateOffice({ officeId, opts }));
  }

  public removeOffice(officeId: string): void {
    this.store.dispatch(officeActions.removeOffice({ officeId }));
  }
  public restoreOffice(officeId: string): void {
    this.store.dispatch(officeActions.restoreOffice({ officeId }));
  }
  public toggleAssignedVoivodeship(opts: ToggleAssignedVoivodeship): void {
    this.store.dispatch(officeActions.toggleAssignedVoivodeship({ opts }));
  }
  public getOfficesSimpleList(): void {
    this.store.dispatch(officeActions.getOfficesSimpleList());
  }
}
