import Step from 'node_modules/shepherd.js/src/types/step';

import { TranslateService } from '@ngx-translate/core';

interface AvailableButtons {
  counter: Step.StepOptionsButton;
  next: Step.StepOptionsButton;
  back: Step.StepOptionsButton;
  cancel: Step.StepOptionsButton;
  done: Step.StepOptionsButton;
}

const gPrefix = 'GUIDE';
const prefix = `${gPrefix}.DOCUMENTS`;

export const getSteps = (t: TranslateService, currLang: string): Step.StepOptions[] => {
  const buttons: AvailableButtons = {
    counter: { text: '', action(): void {}, classes: 'counter-button' },
    next: {
      text: t.instant(`${gPrefix}.NEXT`),
      action(): void {
        this.next();
      },
      classes: 'standard-next-button',
    },
    back: {
      text: t.instant(`${gPrefix}.BACK`),
      action(): void {
        this.back();
      },
      classes: 'standard-back-button',
    },
    cancel: {
      text: t.instant(`${gPrefix}.CLOSE`),
      action(): void {
        this.complete();
      },
      classes: 'standard-cancel-button',
    },
    done: {
      text: t.instant(`${gPrefix}.DONE`),
      action(): void {
        this.complete();
      },
      classes: 'standard-done-button',
    },
  };

  const steps: Step.StepOptions[] = [
    {
      id: 'documentsintro1',
      arrow: false,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,
      // attachTo: { element: '.noPreviousVisits-field', on: 'right' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.documentsintro1.TITLE`),
      // <div>${t.instant(`${prefix}.documentsintro1.TEXT`)}</div> <- not using this line as we're displaying this info in big intro-modal
      text: `
      <div>${t.instant(`${prefix}.documentsintro1.TEXT_2`)}</div>
      <div>${t.instant(`${prefix}.documentsintro1.TEXT_3`)}</div>
      <div>${t.instant(`${prefix}.documentsintro1.TEXT_4`)}</div>
      <div>${t.instant(`${prefix}.documentsintro1.TEXT_5`)}</div>
      `,
    },
    // {
    //   id: 'documentsleftmenu',
    //   arrow: true,
    //   modalOverlayOpeningPadding: 1,
    //   modalOverlayOpeningRadius: 7,
    //   attachTo: { element: '.foreigner-details.menu-item ', on: 'right' },
    //   cancelIcon: { enabled: true },
    //   highlightClass: 'highlight',
    //   scrollTo: false,
    //   title: t.instant(`${prefix}.documentsleftmenu.TITLE`),
    //   text: `
    //   <div>${t.instant(`${prefix}.documentsleftmenu.TEXT`)}</div>
    //   <div>${t.instant(`${prefix}.documentsleftmenu.TEXT_2`)}</div>
    //   `
    // },
    {
      id: 'documentstatusinfo',
      arrow: true,
      modalOverlayOpeningPadding: 6,
      modalOverlayOpeningRadius: 6,
      attachTo: { element: '.mat-step-header', on: 'bottom' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.documentstatusinfo.TITLE`),
      text: `
      <div>
        ${t.instant(`${prefix}.documentstatusinfo.TEXT`)}
        ${t.instant(`${prefix}.documentstatusinfo.TEXT_2`)}
      </div>
      <div class='centered-list'>
        <div>
          <img src='/assets/img/section-indicator-dark-blue.png'>
          ${t.instant(`${prefix}.documentstatusinfo.DOCUMENT_DARK_BLUE`)}
        </div>
        <div>
          <img src='/assets/img/document-indicator-yellow.png'>
          ${t.instant(`${prefix}.documentstatusinfo.DOCUMENT_YELLOW`)}
        </div>
        <div>
          <img src='/assets/img/section-indicator-red.png'>
          ${t.instant(`${prefix}.documentstatusinfo.DOCUMENT_RED`)}
        </div>
        <div>
          <img src='/assets/img/section-indicator-blue.png'>
          ${t.instant(`${prefix}.documentstatusinfo.DOCUMENT_LIGHT_BLUE`)}
        </div>
      </div>
      `,
    },
    {
      id: 'addingfilesallowedfiles',
      arrow: true,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.file-uploader-container', on: 'right' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.addingfilesallowedfiles.TITLE`),
      text: `
      <div>${t.instant(`${prefix}.addingfilesallowedfiles.TEXT`)}</div>
      <div>${t.instant(`${prefix}.addingfilesallowedfiles.TEXT_2`)}</div>
      `,
    },
    {
      id: 'displayedaddedfiles',
      arrow: true,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.file-uploader-container', on: 'right' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.displayedaddedfiles.TITLE`),
      text: `
      <div>${t.instant(`${prefix}.displayedaddedfiles.TEXT`)}</div>
      <br>
      <div class='gif-wrapper bigger-gif'>
        <img src='https://storage.googleapis.com/public-bridge-assets/dropping-files.gif'
        alt='gif displaying how to load files'>
      </div>
      <br>
      <div>${t.instant(`${prefix}.displayedaddedfiles.TEXT_2`)}</div>
      <div class='centered-list'>
        <div>
          <img src='/assets/img/file-status-not-saved.png' width='15px'>
          ${t.instant(`${prefix}.displayedaddedfiles.FILE_NOT_SAVED`)}
        </div>
        <div>
          <img src='/assets/img/file-status-saved.png' width='15px'>
          ${t.instant(`${prefix}.displayedaddedfiles.FILE_SAVED`)}
        </div>
      </div>
      `,
    },
    {
      id: 'addingfilesquestions',
      arrow: true,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.file-uploader-container', on: 'right' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.addingfilesquestions.TITLE`),
      text: `
      <div>${t.instant(`${prefix}.addingfilesquestions.TEXT`)}</div>
      <br>
      <div class='gif-wrapper bigger-gif'>
        <img src='https://storage.googleapis.com/public-bridge-assets/dropping-files.gif'
        alt='gif displaying how to answer questions to files'>
      </div>
      `,
    },
    {
      id: 'savingdocument',
      arrow: true,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.file-uploader-container', on: 'right' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.savingdocument.TITLE`),
      text: `
      <div>${t.instant(`${prefix}.savingdocument.TEXT`)}</div>
      <br>
      <div>${t.instant(`${prefix}.savingdocument.TEXT_2`)}</div>
      <br>
      <div class='gif-wrapper bigger-gif'>
        <img src='https://storage.googleapis.com/public-bridge-assets/saving-and-updating-document.gif'
        alt='gif displaying how to save and update document'>
      </div>
      `,
    },
    {
      id: 'notaddingfiles',
      arrow: true,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.will-not-upload-document-button', on: 'right' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: { behavior: 'smooth' },
      title: t.instant(`${prefix}.notaddingfiles.TITLE`),
      text: `
      <div>${t.instant(`${prefix}.notaddingfiles.TEXT`)}</div>
      <br>
      <div>${t.instant(`${prefix}.notaddingfiles.TEXT_3`)}</div>
      <br>
      <div class='gif-wrapper bigger-gif'>
        <img src='https://storage.googleapis.com/public-bridge-assets/not-uploading-changing-mind.gif'
        alt='gif explaining what to do when scans for document not available'>
      </div>
      `,
    },
    {
      id: 'documentcomments',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.comments-modal-button', on: 'right' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: { behavior: 'smooth' },
      title: t.instant(`${prefix}.documentcomments.TITLE`),
      text: `
      <div>${t.instant(`${prefix}.documentcomments.TEXT`)}</div>
      <br>
      <div>${t.instant(`${prefix}.documentcomments.TEXT_2`)}</div>
      <br>
      <div class='gif-wrapper bigger-gif'>
        <img src='https://storage.googleapis.com/public-bridge-assets/documment-comments-window.gif'
        alt='gif showing how to open comments window'>
      </div>
      `,
    },
    {
      id: 'additionaldocument',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '#addAdditionalFileButton', on: 'top' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.additionaldocument.TITLE`),
      text: `
      <div>${t.instant(`${prefix}.additionaldocument.TEXT`)}</div>
      <br>
      <div>${t.instant(`${prefix}.additionaldocument.TEXT_2`)}</div>
      <br>
      <div class='gif-wrapper bigger-gif'>
        <img src='https://storage.googleapis.com/public-bridge-assets/adding-additional-file.gif'
        alt='gif showcasing adding additional document'>
      </div>
      `,
    },
    {
      id: 'sendingforverification',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.send-application-for-verification-button', on: 'top' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: false,
      title: t.instant(`${prefix}.sendingforverification.TITLE`),
      text: `
      <div>${t.instant(`${prefix}.sendingforverification.TEXT`)}</div>
      <br>
      <div>${t.instant(`${prefix}.sendingforverification.TEXT_2`)}</div>
      <br>
      <div>${t.instant(`${prefix}.sendingforverification.TEXT_3`)}</div>
      `,
    },
  ];

  const guideName = t.instant(`${prefix}.GUIDE_NAME`);
  return steps.map((step, currIndex, all) => {
    const formattedButtons: Step.StepOptionsButton[] = [
      { ...buttons.counter, text: `${guideName} ${currIndex + 1}/${all.length}` },
    ];

    if (currIndex === 0) {
      formattedButtons.push({ ...buttons.cancel });
    } else {
      formattedButtons.push({ ...buttons.back });
    }

    if (currIndex < all.length - 1) {
      formattedButtons.push({ ...buttons.next });
    } else {
      formattedButtons.push({ ...buttons.done });
    }

    return { ...step, buttons: formattedButtons };
  });
};
