import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AppState } from '@state';
import {
  CreateSupportMessageOpts,
  FindSupportChatsOpts,
  MarkSupportChatOpts,
  SupportChat,
  SupportChatMessage,
  UploadMessageAttachmentsOpts,
} from '@interfaces';
import * as supportChatActions from './support-chat.actions';
import * as selectors from './support-chat.selectors';
import { debounce } from 'lodash-es';

@Injectable()
export class SupportChatFacade {
  public supportChatsList$ = this.store.select(selectors.selectSupportChats);
  public pagination$ = this.store.select(selectors.selectPagination);
  public loadingChatsList$ = this.store.select(selectors.selectLoadingSupportChats);
  public loadingMoreChats$ = this.store.select(selectors.selectLoadingMoreSupportChats);

  public openSupportChat$ = this.store.select(selectors.selectOpenSupportChat);
  public loadingOpenSupportChat$ = this.store.select(selectors.selectLoadingOpenSupportChat);
  public loadingOpenSupportChatUpdate$ = this.store.select(selectors.selectLoadingSupportChatUpdate);

  public myUnreadCount$ = this.store.select(selectors.selectUnreadCount);

  public createSupportMessageSuccess$ = this.actions.pipe(ofType(supportChatActions.createSupportMessageSuccess));
  public editSupportMessageSuccess$ = this.actions.pipe(ofType(supportChatActions.editSupportMessageSuccess));
  public removeSupportMessageSuccess$ = this.actions.pipe(ofType(supportChatActions.removeSupportMessageSuccess));

  public openSupportChatSuccess$ = this.actions.pipe(ofType(supportChatActions.openSupportChatSuccess));
  public closeSupportChatSuccess$ = this.actions.pipe(ofType(supportChatActions.closeSupportChatSuccess));

  public getTranscriptionSuccess$ = this.actions.pipe(ofType(supportChatActions.getTranscriptionSuccess));
  public getTranscriptionError$ = this.actions.pipe(ofType(supportChatActions.getTranscriptionError));

  public removeExistingMessageAttachmentSuccess$ = this.actions.pipe(
    ofType(supportChatActions.removeExistingAttachmentSuccess)
  );

  public debouncedMarkSupportChatAsRead = debounce(supportChatId => this.markSupportChatAsRead(supportChatId), 1000);
  public debouncedGetMyUnreadCount = debounce(() => this.getMyUnreadCount(), 700);

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public searchSupportChats(opts: FindSupportChatsOpts): void {
    this.store.dispatch(supportChatActions.searchSupportChats({ opts }));
  }

  public loadMoreSupportChats(opts: FindSupportChatsOpts): void {
    this.store.dispatch(supportChatActions.loadMoreSupportChats({ opts }));
  }

  public openSupportChat(supportChatId: string): void {
    this.store.dispatch(supportChatActions.openSupportChat({ supportChatId }));
  }
  public closeSupportChat(supportChatId: string): void {
    this.store.dispatch(supportChatActions.closeSupportChat({ supportChatId }));
  }

  public createSupportMessage(opts: CreateSupportMessageOpts): void {
    this.store.dispatch(supportChatActions.createSupportMessage({ opts }));
  }

  // Used by websockets on socket events
  public updateSupportChatAndPosition(supportChat: SupportChat): void {
    this.store.dispatch(supportChatActions.updateSupportChatAndPosition({ supportChat }));
  }

  // Used by websockets on socket events
  public updateSupportChat(supportChat: SupportChat): void {
    this.store.dispatch(supportChatActions.updateSupportChat({ supportChat }));
  }

  public newMessageReceived(supportMessage: SupportChatMessage): void {
    this.store.dispatch(supportChatActions.createSupportMessageSuccess({ supportMessage }));
  }
  public messageEditedByOther(supportMessage: SupportChatMessage): void {
    this.store.dispatch(supportChatActions.editSupportMessageSuccess({ supportMessage }));
  }
  public messageRemovedByOther(supportMessage: SupportChatMessage): void {
    this.store.dispatch(supportChatActions.removeSupportMessageSuccess({ removedSupportMessageId: supportMessage.id }));
  }

  public addSupportChatMarker(opts: MarkSupportChatOpts): void {
    this.store.dispatch(supportChatActions.addSupportChatMarker({ opts }));
  }

  public markSupportChatAsUnread(supportChatId: string): void {
    this.store.dispatch(supportChatActions.markSupportChatAsUnread({ supportChatId }));
  }
  public markSupportChatAsRead(supportChatId: string): void {
    this.store.dispatch(supportChatActions.markSupportChatAsRead({ supportChatId }));
  }

  public editSupportMessage(supportMessageId: string, opts: CreateSupportMessageOpts): void {
    this.store.dispatch(supportChatActions.editSupportMessage({ supportMessageId, opts }));
  }

  public removeSupportMessage(supportChatId: string, supportMessageId: string): void {
    this.store.dispatch(supportChatActions.removeSupportMessage({ supportChatId, supportMessageId }));
  }

  public uploadMessageAttachments(opts: UploadMessageAttachmentsOpts): void {
    this.store.dispatch(supportChatActions.uploadAttachments({ opts }));
  }

  public downloadAttachmentFromChat(opts: { supportChatId: string; messageId: string; attachmentId: string }): void {
    this.store.dispatch(supportChatActions.downloadAttachmentFromChat({ ...opts }));
  }

  public removeAttachmentFromUploading(opts: { userAssetId: string; fileOriginalName: string }): void {
    this.store.dispatch(supportChatActions.removeAttachmentFromUploading({ opts }));
  }

  public removeExistingMessageAttachment(opts: {
    supportChatId: string;
    supportMessageId: string;
    attachmentId: string;
  }): void {
    this.store.dispatch(supportChatActions.removeExistingAttachment({ opts }));
  }

  public getMyUnreadCount(): void {
    this.store.dispatch(supportChatActions.getMyUnreadCount());
  }

  public getTranscription(supportChatId: string): void {
    this.store.dispatch(supportChatActions.getTranscription({ supportChatId }));
  }
}
