import { Pagination, Purchase, Role, User, Company, UserProcess } from '@interfaces';

export type BackOfficeState = Readonly<{
  roles: Role[] | null;
  companies: Company[] | null;
  employees: User[] | null;

  loading: boolean;
  errorMessage: string;

  loadingUsers: boolean;
  loadedUsersPagination: Pagination;
  errorUsersMessage: string | null;
  loadedUsersData: User[];

  loadingTransactions: boolean;
  loadedTransactionsPagination: Pagination;
  errorTransactionsMessage: string | null;
  loadedTransactionsData: Purchase[];
  downloadingPurchaseDocument: boolean;
  generatingTransactionsReport: boolean;

  loadingApplications: boolean;
  loadedApplicationsPagination: Pagination;
  errorApplicationsMessage: string | null;
  loadedApplicationsData: UserProcess[];
  generatingApplicationReport: boolean;
}>;

export const defaultPagination = {
  limit: 25,
  offset: 0,
  orderBy: 'name',
  orderDir: 'asc',
};

export const initialState: BackOfficeState = {
  roles: null,
  companies: null,
  employees: null,

  loading: false,
  errorMessage: null,

  loadingUsers: false,
  loadedUsersPagination: { ...(defaultPagination as Pagination) },
  errorUsersMessage: null,
  loadedUsersData: [],

  loadingTransactions: false,
  loadedTransactionsPagination: { ...(defaultPagination as Pagination) },
  errorTransactionsMessage: null,
  loadedTransactionsData: [],
  downloadingPurchaseDocument: false,
  generatingTransactionsReport: false,

  loadingApplications: false,
  loadedApplicationsPagination: { ...(defaultPagination as Pagination) },
  errorApplicationsMessage: null,
  loadedApplicationsData: [],
  generatingApplicationReport: false,
};
