import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CommonsFacade } from '@state/commons';

import { ConsumableCountry } from '@interfaces';
import { AvailableLanguages } from '@constants';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { merge, ReplaySubject, Subscription } from 'rxjs';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  customDateComparisonValidator,
  forbiddenFutureDate,
  forbiddenDateInPast,
  customVisitDateValidator,
} from '@shared/custom-validators';
import { ManagementFacade } from '@state/management';
import { UserProcessFacade } from '@state/user-process';

@Component({
  selector: 'app-countries-visits-form-temp-premit',
  templateUrl: './countries-visits-form-temp-premit.component.html',
  styleUrls: ['./countries-visits-form-temp-premit.component.scss'],
})
export class CountriesVisitsFormTempPremitComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @Input() lastTravelsOutsidePoland: AbstractControl;
  @Output() lastFieldTabPressed: EventEmitter<Event> = new EventEmitter<Event>();

  public countriesList: ConsumableCountry[];
  public currentLang: AvailableLanguages;
  public visitsArray: FormGroup[] = [];

  public showLoading = false;
  public showSaved = false;

  public savedSuccess$ = merge(
    this.userProcessFacade.updatePersonalDetailsSuccess$,
    this.managementFacade.updatePersonalDetailsSuccess$
  );

  public oldTravelsValue: string;

  private groupsValueChangesSubscriptions: Subscription[] = [];

  constructor(
    private readonly commonsFacade: CommonsFacade,
    private readonly translateService: TranslateService,
    private readonly fb: FormBuilder,
    private readonly userProcessFacade: UserProcessFacade,
    private readonly managementFacade: ManagementFacade
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang as AvailableLanguages;

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang: langKey }) => {
      this.currentLang = langKey as AvailableLanguages;
    });

    this.commonsFacade
      .getCountries$()
      .pipe(take(1))
      .subscribe(countriesList => {
        this.countriesList = countriesList;
        const visitsString = this.lastTravelsOutsidePoland.value as string | null;
        this.oldTravelsValue = visitsString;
        // if (!visitsString?.length) { return; }

        // visitsString is in format:
        // `[countryName]: [dateEnter]-[dateLeave], [countryName]: [dateEnter]-[dateLeave], [countryName]: [dateEnter]-[dateLeave]`
        // so we can use comma as delimiter for visit
        const visits = visitsString?.split(',');
        if (!visits?.length) {
          const { group, groupSubscription } = this.getNewVisitGroup();
          this.visitsArray.push(group);
          this.groupsValueChangesSubscriptions.push(groupSubscription);
          return;
        }

        visits.forEach((visitString, i) => {
          const { group, groupSubscription } = this.getNewVisitGroup(visitString.trim());
          this.visitsArray.push(group);
          this.groupsValueChangesSubscriptions.push(groupSubscription);
        });

        this.saveVisits();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public getControl(name: string, groupIndex: number): FormControl {
    return this.visitsArray[groupIndex].get(name) as FormControl;
  }

  public addNewVisit(): void {
    this.showSaved = false;
    const { group, groupSubscription } = this.getNewVisitGroup();
    this.visitsArray.push(group);
    this.groupsValueChangesSubscriptions.push(groupSubscription);
  }

  public getNewVisitGroup(visitString?: string): { group: FormGroup; groupSubscription: Subscription } {
    const visitDateValidatorsArray = [
      Validators.required,
      customVisitDateValidator,
      forbiddenDateInPast({ specificDate: '01.01.2000' }),
      forbiddenFutureDate(),
    ];

    const group: FormGroup<any> = this.fb.group(
      {
        country: [null, Validators.compose([Validators.required])],
        dateEnter: [null, Validators.compose([...visitDateValidatorsArray])],
        dateLeave: [null, Validators.compose([...visitDateValidatorsArray])],
      },
      { validators: customDateComparisonValidator('dateEnter', 'dateLeave') }
    );

    // visitString is in form: `[countryName]: [dateEnter]-[dateLeave]`
    if (visitString) {
      const [country, dates] = visitString.split(':');
      if (country?.length && dates?.length) {
        const [dateEnter, dateLeave] = dates.trim().split('-');
        group.patchValue({ country, dateEnter, dateLeave });
      }
    }

    const groupSubscription = group.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe(() => {
      this.showSaved = false;
      if (!group.valid) {
        return;
      }
      this.saveVisits();
    });
    return { group, groupSubscription };
  }

  public removeVisit(groupIndex: number): void {
    // Not possible to remove last visit
    if (this.visitsArray.length === 1) {
      return;
    }
    this.showSaved = false;
    this.visitsArray.splice(groupIndex, 1);
    const sub = this.groupsValueChangesSubscriptions[groupIndex];
    sub.unsubscribe();
    this.groupsValueChangesSubscriptions.splice(groupIndex, 1);
    this.saveVisits();
  }

  public saveVisits(): void {
    const allCorrect = this.visitsArray.every(fg => fg.valid);
    if (!allCorrect) {
      return;
    }

    this.showLoading = true;
    this.showSaved = false;
    const visits: string[] = this.visitsArray.map(visitGroup => {
      const { country, dateEnter, dateLeave } = visitGroup.value;
      return `${country.trim()}: ${dateEnter.trim()}-${dateLeave.trim()}`;
    });

    const visitsString = visits.join(', ').trim();

    if (this.oldTravelsValue !== visitsString) {
      this.savedSuccess$.pipe(take(1)).subscribe(() => {
        this.showLoading = false;
        this.showSaved = true;
      });
    } else {
      this.showLoading = false;
      this.showSaved = true;
    }

    // If empty string then user did not provide any info - null;
    const valToSet = visitsString ? visitsString : null;
    this.lastTravelsOutsidePoland.setValue(valToSet);
    this.oldTravelsValue = valToSet;
  }
}
