import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { patterns } from '@constants';

export const newAccountFormFields = {
  name: new FormControl('', [
    Validators.required,
    Validators.pattern(patterns.LatinAndCyrylicName),
    Validators.minLength(2),
    Validators.maxLength(50),
  ]),
  surname: new FormControl('', [
    Validators.required,
    Validators.pattern(patterns.LatinAndCyrylicName),
    Validators.minLength(2),
    Validators.maxLength(50),
  ]),
  email: new FormControl('', [Validators.required, Validators.email]),
  confirmEmail: new FormControl('', [Validators.required, Validators.email]),
  telephoneNumber: new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(20),
    Validators.pattern(patterns.PhoneNumberValidator),
  ]),
  hasAcceptedGDPR: new FormControl(false, [Validators.required]),
  langKey: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]),
  referalCode: new FormControl('', [Validators.minLength(5), Validators.maxLength(100)]),
};

export const wholeFormValidator: ValidatorFn = (fg: AbstractControl): ValidationErrors | null => {
  const emailFc = fg.get('email');
  const confirmEmailFc = fg.get('confirmEmail');
  const email = emailFc.value;
  const confirmEmail = confirmEmailFc.value;

  if (email?.length && confirmEmail?.length && email !== confirmEmail) {
    confirmEmailFc.setErrors({ confirmEmail: 'VALUES_DO_NOT_MATCH' });
    confirmEmailFc.markAsDirty();
    return { confirmEmail: 'VALUES_DO_NOT_MATCH' };
  }

  return null;
};

export const GDPRValidator: ValidatorFn = (fg: AbstractControl): ValidationErrors | null => {
  const hasAcceptedGDPR = fg.get('hasAcceptedGDPR').value;

  return hasAcceptedGDPR ? null : { GDPRRequired: true };
};

export interface NewAccountFormGroup {
  name: FormControl<string>;
  surname: FormControl<string>;
  email: FormControl<string>;
  confirmEmail: FormControl<string>;
  telephoneNumber: FormControl<string>;
  hasAcceptedGDPR: FormControl<boolean>;
  langKey: FormControl<string>;
  referalCode: FormControl<string>;
}
