import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '@core/local-storage.service';
import { Language, PermissionEnum, ROLES_KEYS } from '@interfaces';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { RouterFacade } from '@state/router';

import { defaultLanguages, roleToPath } from '@constants';
import { AllDeviceInfo } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { CommonsFacade } from '@state/commons';
import { UserAuthFacade } from '@state/user-auth';
import { ReplaySubject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public loginForm = this.fb.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
  });

  public errorMessage$ = this.userAuthFacade.errorMessage$;
  public loading$ = this.userAuthFacade.loading$;

  public currentUserLang: Language;
  public safeLogoutInfo = false;

  public isPasswordVisible = false;

  public APIAvailable = true;

  private redirect: string | null = null;

  public deviceInfo: AllDeviceInfo;

  constructor(
    private fb: FormBuilder,
    private userAuthFacade: UserAuthFacade,
    private activatedRoute: ActivatedRoute,
    private snackService: SnackbarService,
    private routerFacade: RouterFacade,
    private readonly ls: LocalStorageService,
    private readonly translateService: TranslateService,
    private readonly commonsFacade: CommonsFacade,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    const { email, message, redirect, safeLogout } = this.activatedRoute.snapshot.queryParams;

    this.commonsFacade.lastAPIPingSuccessfull$.pipe(takeUntil(this.destroy$)).subscribe(APIPingSuccess => {
      if (!APIPingSuccess) {
        // if API connection failed - setup langs with default Languages hardcoded
        this.setupUserLang([...defaultLanguages]);
        this.APIAvailable = false;
        return;
      }

      this.APIAvailable = true;
    });

    this.commonsFacade
      .getLanguages$()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(availableLanguages => {
        // setup user lang with languages fetched from API
        this.setupUserLang([...availableLanguages]);
      });

    if (email) {
      this.loginForm.patchValue({ email });
    }

    if (message) {
      this.snackService.showInfo(message);
    }

    if (redirect) {
      this.redirect = redirect;
    }

    if (safeLogout) {
      this.safeLogoutInfo = true;
    }

    this.userAuthFacade.getMyselfSuccess$
      .pipe(
        filter(({ user }) => {
          if (!user?.role?.key || !user?.role?.permissions?.length) {
            return false;
          }
          return true;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(({ user }) => {
        const roleKey = user.role.key as ROLES_KEYS;
        let linkParams = [roleToPath[roleKey]];
        const queryParams = {} as any;

        // if redirect string shorter than 4 - no redirect - its not possible
        if (this.redirect?.length >= 4) {
          const [path, queryParamsString] = this.redirect.split('?');
          linkParams = path.split('/').filter(pathPart => !!pathPart);

          if (queryParamsString?.length) {
            queryParamsString.split('&').forEach(keyVal => {
              const [key, val] = keyVal.split('=');
              queryParams[key] = val;
            });
          }
        }

        // Special case of permissions: User using MOBILE device MUST HAVE CAN_USE_MOBILE permission to enter the app
        // If no permmission granted - show message about forbidden usage with mobile-device
        // This is due to requirement that: Foreigners, Admins & Team_Leaders might use mobile-device to use the app
        // While Employee & Field_Consultants must not.
        const usingMobileDevice = this.deviceInfoService.getDeviceType() !== 'DESKTOP';
        const isAllowedToUseMobileDevice = user.role.permissions.find(
          permission => permission.key === PermissionEnum.CAN_USE_MOBILE
        );
        if (usingMobileDevice && !isAllowedToUseMobileDevice) {
          this.snackService.showError('ERRORS.NOT_AUTHORIZED_TO_USE_MOBILE_DEVICE');
          this.userAuthFacade.logout({ wasUnauthorized: false, sessionTimedOutLogout: false });
          return;
        }

        this.routerFacade.changeRoute({ linkParams, extras: { queryParams } });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public userLangChanged($event: Language): void {
    this.currentUserLang = $event;
  }

  public submitLoginForm(): boolean {
    if (!this.loginForm.valid) {
      return false;
    }

    this.loginForm.markAsPristine();
    const email = this.loginForm.get('email')?.value;
    const password = this.loginForm.get('password')?.value;
    const redirect = this.redirect;
    this.snackService.closeAll();
    this.userAuthFacade.login(email, password, this.currentUserLang.id, redirect);
    return false;
  }

  private setupUserLang(langs: Language[]): void {
    const { langKey } = this.activatedRoute.snapshot.queryParams;

    // lang from query params is most important
    const langInQueryParams = langs.find(({ key }) => key === langKey);

    // if no lang in query params then localStorage
    const langInLS = this.ls.getUserLang();

    // if no lang in Query & no in LS use default
    const defaultLang = langs.find(({ key }) => key === 'en');

    // 1. langInQuery 2. langInLS 3. defaultLang
    const langToUse = langInQueryParams?.key ? langInQueryParams : langInLS?.key ? langInLS : defaultLang;

    this.ls.setUserLang(langToUse);
    this.translateService.use(langToUse.key);
    this.currentUserLang = langToUse;
  }
}
