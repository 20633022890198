import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BackOfficeState } from '@state/back-office/back-office.state';

export const selectBackOfficeState = createFeatureSelector<BackOfficeState>('backOffice');

export const selectRoles = createSelector(selectBackOfficeState, state => state.roles);
export const selectCompanies = createSelector(selectBackOfficeState, state => state.companies);
export const selectEmployees = createSelector(selectBackOfficeState, state => state.employees);

export const selectUsersLoading = createSelector(selectBackOfficeState, state => state.loadingUsers);
export const selectUsersLoadedPagination = createSelector(selectBackOfficeState, state => state.loadedUsersPagination);
export const selectUsersErrorMessage = createSelector(selectBackOfficeState, state => state.errorUsersMessage);
export const selectUsers = createSelector(selectBackOfficeState, state => state.loadedUsersData);

export const selectTransactionsLoading = createSelector(selectBackOfficeState, state => state.loadingTransactions);
export const selectTransactionsLoadedPagination = createSelector(
  selectBackOfficeState,
  state => state.loadedTransactionsPagination
);
export const selectTransactionsErrorMessage = createSelector(
  selectBackOfficeState,
  state => state.errorTransactionsMessage
);
export const selectTransactions = createSelector(selectBackOfficeState, state => state.loadedTransactionsData);
export const selectTransactionsDownloading = createSelector(
  selectBackOfficeState,
  state => state.downloadingPurchaseDocument
);
export const selectTransactionsGenerating = createSelector(
  selectBackOfficeState,
  state => state.generatingTransactionsReport
);

export const selectApplicationsLoading = createSelector(selectBackOfficeState, state => state.loadingApplications);
export const selectApplicationsLoadedPagination = createSelector(
  selectBackOfficeState,
  state => state.loadedApplicationsPagination
);
export const selectApplicationsErrorMessage = createSelector(
  selectBackOfficeState,
  state => state.errorApplicationsMessage
);
export const selectApplications = createSelector(selectBackOfficeState, state => state.loadedApplicationsData);
export const selectApplicationsGenerating = createSelector(
  selectBackOfficeState,
  state => state.generatingApplicationReport
);
