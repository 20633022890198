<div class="title-container">
  <h2 mat-dialog-title>
    {{ 'VISIT-DATE-MODAL.SETTING-VISIT-DATE-FOR' | translate }}
    <ng-container *ngIf="data.userProcess.personalDetails?.name && data.userProcess.personalDetails?.surname">
      {{ data.userProcess.personalDetails.name }} {{ data.userProcess.personalDetails.surname }}
    </ng-container>
  </h2>
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography" #dialogContent>
  <ng-container *ngIf="data.userProcess.hasFullHandling && !data.userProcess?.availabilityForOfficeVisit?.mode">
    <div class="foreigner-dates-wrapper">
      <h3>{{ 'VISIT-DATE-MODAL.FOREIGNER-DID-NOT-PROVIDE-AVAILABILITY' | translate }}</h3>
    </div>
  </ng-container>

  <ng-container
    *ngIf="data.userProcess.hasFullHandling && data.userProcess?.availabilityForOfficeVisit as availability">
    <div *ngIf="availability.mode === AvailabilityMode.PARTICULAR_DATES" class="foreigner-dates-wrapper">
      <h3>{{ 'VISIT-DATE-MODAL.FOREIGNER-SET-DATES-AS-AVAILABLE' | translate }}</h3>
      <mat-chip-list [selectable]="true" [multiple]="false">
        <mat-chip
          [selected]="date === visitDate?.format('DD.MM.yyyy')"
          *ngFor="let date of availability.dates; index as chipIndex"
          (click)="setDateFromChip(date)">
          {{ date }}
        </mat-chip>
      </mat-chip-list>
    </div>
    <div *ngIf="availability.mode === AvailabilityMode.DATE_RANGE" class="foreigner-dates-wrapper">
      <h3>
        {{ 'VISIT-DATE-MODAL.FOREIGNER-SET-DATE-RANGE-FOR-AVAILABILITY' | translate }}
      </h3>
      <h2>{{ availability.dates[0] }} - {{ availability.dates[1] }}</h2>
    </div>
    <div *ngIf="availability.mode === AvailabilityMode.ASAP" class="foreigner-dates-wrapper">
      <h3 [innerHTML]="'NT.FOREIGNER_ASKED_FOR_OFFICE_VISIT_ASAP' | translate"></h3>
    </div>
  </ng-container>

  <div class="date-input-wrapper">
    <!-- <h3> {{'VISIT-DATE-MODAL.ENTER-VISIT-DATE' | translate }}</h3> -->
    <mat-form-field appearance="fill" (click)="picker.open()">
      <mat-label>{{ 'VISIT-DATE-MODAL.ENTER-VISIT-DATE' | translate }}</mat-label>
      <input
        type="text"
        autocomplete="off"
        id="visitDatePickerInput"
        matInput
        [(ngModel)]="visitDate"
        [matDatepicker]="picker" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [startAt]="currentDate" [startView]="'month'" [calendarHeaderComponent]="pickerHeader">
      </mat-datepicker>
    </mat-form-field>
  </div>

  <div class="time-input-wrapper">
    <!-- <h3> {{'VISIT-DATE-MODAL.ENTER-VISIT-TIME' | translate }}</h3> -->
    <mat-form-field appearance="fill">
      <mat-label>{{ 'VISIT-DATE-MODAL.ENTER-VISIT-TIME' | translate }}</mat-label>
      <input type="text" autocomplete="off" id="visitTimeInput" matInput [(ngModel)]="visitTime" [mask]="'00:00'" />
      <mat-error *ngIf="!visitTime || visitTime.length < 5">
        {{ 'VISIT-DATE-MODAL.INVALID-TIME' | translate }}
        {{ 'VISIT-DATE-MODAL.ONLY-VALID-TIME-FORMAT' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="selected-office-wrapper">
    <mat-form-field appearance="fill">
      <mat-label>
        {{ 'NT.IMMIGRATION_OFFICE' | translate }}
      </mat-label>
      <mat-select
        [(ngModel)]="selectedVoivodeshipOfficeId"
        id="voivodeshipOfficeSelect"
        [panelClass]="['standard-select']">
        <mat-option *ngFor="let office of offices" [value]="office.id">
          {{ office.city }}, {{ office.street }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="reservation-number-wrapper">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'VISIT-DATE-MODAL.RESERVATION_NUMBER' | translate }}</mat-label>
      <input
        type="text"
        autocomplete="off"
        matInput
        id="reservationNumberInput"
        [(ngModel)]="officeVisitReservationNumber" />
    </mat-form-field>
  </div>

  <div class="visit-assistant-wrapper">
    <mat-form-field appearance="fill" *ngIf="(loadingEmployees$ | async) === false; else spinnerForEmployees">
      <mat-label>{{ 'VISIT-DATE-MODAL.OFFICE_VISIT_ASSISTANT' | translate }}</mat-label>
      <mat-select
        [(ngModel)]="visitAssistant"
        [compareWith]="compareUsers"
        [disabled]="loadingEmployees$ | async"
        id="assistantSelect"
        [panelClass]="['standard-select', 'visit-assistant-select']">
        <mat-select-trigger>{{ visitAssistant?.name }}</mat-select-trigger>

        <ng-container *ngIf="(loadingEmployees$ | async) === false; else loadingAssistants">
          <mat-option [value]="null"> - </mat-option>
          <mat-option *ngFor="let user of employees$ | async" [value]="user">
            <div class="assistant-option" [class.highlight]="user.role.key === ROLES_KEYS.Field_Consultant">
              <div class="user-name">{{ user.name }}</div>
              <div class="user-telephoneNumber">{{ user.telephoneNumber }}</div>
              <div class="user-roleName">
                {{ 'NT.ROLES_NAMES.' + user.role.key | translate }}
              </div>
            </div>
          </mat-option>
        </ng-container>

        <ng-template #loadingAssistants>
          <mat-option [value]="null" [disabled]="true" class="loading-assistants-option">
            <mat-spinner [diameter]="45"></mat-spinner>
          </mat-option>
        </ng-template>
      </mat-select>
    </mat-form-field>
    <ng-template #spinnerForEmployees>
      <mat-spinner [diameter]="35"></mat-spinner>
    </ng-template>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="action-buttons-overline">
    <mat-checkbox
      class="updateStatusAndNotifyForeigner-checkbox"
      [(ngModel)]="updateStatusAndNotifyForeigner"
      [labelPosition]="'before'">
      {{ 'VISIT-DATE-MODAL.UPDATE_STATUS_AND_NOTIFY_FOREIGNER' | translate }}
    </mat-checkbox>
  </div>
  <div class="action-buttons">
    <button mat-raised-button mat-dialog-close>{{ 'COMMON.CANCEL' | translate }}</button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="
        !visitDate || !visitTime || !selectedVoivodeshipOfficeId || visitTime.length !== 4 || !visitAssistant?.id
      "
      (click)="submitDate()">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </div>
</mat-dialog-actions>
