import { Action, createReducer, on } from '@ngrx/store';
import { BackOfficeState, initialState } from '@state/back-office';
import * as usersActions from '@state/back-office/back-office.actions';

const reducer = createReducer(
  initialState,

  on(usersActions.getRoles, state => ({
    ...state,
    loading: true,
    errorMessage: null,
    roles: [],
  })),
  on(usersActions.getRolesSuccess, (state, { roles }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    roles,
  })),
  on(usersActions.getRolesError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    roles: [],
  })),

  on(usersActions.getCompanies, state => ({
    ...state,
    loading: true,
    errorMessage: null,
    companies: [],
  })),
  on(usersActions.getCompaniesSuccess, (state, { companies }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    companies,
  })),
  on(usersActions.getCompaniesError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    companies: [],
  })),

  on(usersActions.getEmployees, state => ({
    ...state,
    loading: true,
    errorMessage: null,
    employees: [],
  })),
  on(usersActions.getEmployeesSuccess, (state, { employees }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    employees,
  })),
  on(usersActions.getEmployeesError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    employees: [],
  })),

  // ############
  // Users
  // ############

  on(usersActions.getUsers, state => ({
    ...state,
    loadingUsers: true,
    errorUsersMessage: null,
    loadedUsersData: [],
  })),
  on(usersActions.getUsersSuccess, (state, { users }) => ({
    ...state,
    loadingUsers: false,
    errorUsersMessage: null,
    loadedUsersData: users.data,
    loadedUsersPagination: users.pagination,
  })),
  on(usersActions.getUsersError, (state, { errorUsersMessage }) => ({
    ...state,
    errorUsersMessage,
    loadingUsers: false,
    loadedUsersData: [],
  })),

  on(usersActions.usersActivateAccount, state => ({
    ...state,
    loadingUsers: true,
  })),
  on(usersActions.usersActivateAccountSuccess, state => ({
    ...state,
    loadingUsers: false,
  })),
  on(usersActions.usersActivateAccountError, (state, { errorUsersMessage }) => ({
    ...state,
    errorUsersMessage,
    loadingUsers: false,
  })),

  on(usersActions.usersDeactivateAccount, state => ({
    ...state,
    loadingUsers: true,
  })),
  on(usersActions.usersDeactivateAccountSuccess, state => ({
    ...state,
    loadingUsers: false,
  })),
  on(usersActions.usersDeactivateAccountError, (state, { errorUsersMessage }) => ({
    ...state,
    errorUsersMessage,
    loadingUsers: false,
  })),

  on(usersActions.usersRestoreAccount, state => ({
    ...state,
    loadingUsers: true,
  })),
  on(usersActions.usersRestoreAccountSuccess, state => ({
    ...state,
    loadingUsers: false,
  })),
  on(usersActions.usersRestoreAccountError, (state, { errorUsersMessage }) => ({
    ...state,
    errorUsersMessage,
    loadingUsers: false,
  })),

  on(usersActions.usersChangePassword, state => ({
    ...state,
    loadingUsers: true,
  })),
  on(usersActions.usersChangePasswordSuccess, state => ({
    ...state,
    loadingUsers: false,
  })),
  on(usersActions.usersChangePasswordError, (state, { errorUsersMessage }) => ({
    ...state,
    errorUsersMessage,
    loadingUsers: false,
  })),

  on(usersActions.usersSoftDelete, state => ({
    ...state,
    loadingUsers: true,
  })),
  on(usersActions.usersSoftDeleteSuccess, state => ({
    ...state,
    loadingUsers: false,
  })),
  on(usersActions.usersSoftDeleteError, (state, { errorUsersMessage }) => ({
    ...state,
    errorUsersMessage,
    loadingUsers: false,
  })),

  // ############
  // Transactions
  // ############

  on(usersActions.getTransactions, state => ({
    ...state,
    loadingTransactions: true,
    errorTransactionsMessage: null,
    loadedTransactionsData: [],
  })),
  on(usersActions.getTransactionsSuccess, (state, { transactions }) => ({
    ...state,
    loadingTransactions: false,
    errorTransactionsMessage: null,
    loadedTransactionsData: transactions.data,
    loadedTransactionsPagination: transactions.pagination,
  })),
  on(usersActions.getTransactionsError, (state, { errorTransactionsMessage }) => ({
    ...state,
    errorTransactionsMessage,
    loadingTransactions: false,
    loadedTransactionsData: [],
  })),

  on(usersActions.transactionsDownloadPurchaseDocument, state => ({
    ...state,
    downloadingPurchaseDocument: true,
    errorTransactionsMessage: null,
  })),
  on(usersActions.transactionsDownloadPurchaseDocumentSuccess, state => ({
    ...state,
    downloadingPurchaseDocument: false,
    errorTransactionsMessage: null,
  })),
  on(usersActions.transactionsDownloadPurchaseDocumentError, (state, { errorTransactionsMessage }) => ({
    ...state,
    downloadingPurchaseDocument: false,
    errorTransactionsMessage,
  })),

  on(usersActions.transactionsGenerateReport, state => ({
    ...state,
    generatingTransactionsReport: true,
  })),
  on(usersActions.transactionsGenerateReportSuccess, state => ({
    ...state,
    generatingTransactionsReport: false,
  })),
  on(usersActions.transactionsGenerateReportError, (state, { errorTransactionsMessage }) => ({
    ...state,
    errorTransactionsMessage,
    generatingTransactionsReport: false,
  })),

  // ############
  // Transactions
  // ############

  on(usersActions.getApplications, state => ({
    ...state,
    loadingApplications: true,
    errorApplicationsMessage: null,
    loadedApplicationsData: [],
  })),
  on(usersActions.getApplicationsSuccess, (state, { applications }) => ({
    ...state,
    loadingApplications: false,
    errorApplicationsMessage: null,
    loadedApplicationsData: applications.data,
    loadedApplicationsPagination: applications.pagination,
  })),
  on(usersActions.getApplicationsError, (state, { errorApplicationsMessage }) => ({
    ...state,
    errorApplicationsMessage,
    loadingApplications: false,
    loadedApplicationsData: [],
  })),

  on(usersActions.applicationsGenerateReport, state => ({
    ...state,
    generatingApplicationReport: true,
  })),
  on(usersActions.applicationsGenerateReportSuccess, state => ({
    ...state,
    generatingApplicationReport: false,
  })),
  on(usersActions.applicationsGenerateReportError, (state, { errorApplicationsMessage }) => ({
    ...state,
    errorApplicationsMessage,
    generatingApplicationReport: false,
  }))
);

export function backOfficeReducer(state: BackOfficeState, action: Action): BackOfficeState {
  return reducer(state, action);
}
