import Step from 'node_modules/shepherd.js/src/types/step';

import { TranslateService } from '@ngx-translate/core';

interface AvailableButtons {
  counter: Step.StepOptionsButton;
  next: Step.StepOptionsButton;
  back: Step.StepOptionsButton;
  cancel: Step.StepOptionsButton;
  done: Step.StepOptionsButton;
}

const gPrefix = 'GUIDE';
const prefix = `${gPrefix}.PERSONAL-DATA-FORM`;

export const getSteps = (t: TranslateService, currLang: string): Step.StepOptions[] => {
  const buttons: AvailableButtons = {
    counter: { text: '', action(): void {}, classes: 'counter-button' },
    next: {
      text: t.instant(`${gPrefix}.NEXT`),
      action(): void {
        this.next();
      },
      classes: 'standard-next-button',
    },
    back: {
      text: t.instant(`${gPrefix}.BACK`),
      action(): void {
        this.back();
      },
      classes: 'standard-back-button',
    },
    cancel: {
      text: t.instant(`${gPrefix}.CLOSE`),
      action(): void {
        this.complete();
      },
      classes: 'standard-cancel-button',
    },
    done: {
      text: t.instant(`${gPrefix}.DONE`),
      action(): void {
        this.complete();
      },
      classes: 'standard-done-button',
    },
  };

  const steps: Step.StepOptions[] = [
    {
      id: 'personaldataformintro',
      arrow: true,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 20,
      attachTo: { element: '.menu-items-container .foreigner-details', on: 'right-end' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.personaldataformintro.TITLE`),
      text: `${t.instant(`${prefix}.personaldataformintro.TEXT`)}`,
    },
    {
      id: 'personaldataformsteps',
      arrow: true,
      modalOverlayOpeningPadding: 1,
      modalOverlayOpeningRadius: 20,
      attachTo: { element: '.mat-step-header', on: 'bottom' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.personaldataformsteps.TITLE`),
      text: `${t.instant(`${prefix}.personaldataformsteps.TEXT`)}
      <div class="gif-wrapper bigger-gif">
        <img src='https://storage.googleapis.com/public-bridge-assets/select-form-step.gif'
        alt='gif showing how to seelct step in form and reveal it'>
      </div>
      `,
    },
    {
      id: 'personaldataformintro2',
      arrow: true,
      modalOverlayOpeningPadding: 1,
      modalOverlayOpeningRadius: 20,
      attachTo: { element: '.mat-step-header', on: 'bottom' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.personaldataformintro2.TITLE`),
      text: `${t.instant(`${prefix}.personaldataformintro2.TEXT`)}
      <div class='centered-list'>
        <div>
          <img src='/assets/img/section-indicator-dark-blue.png'>
          ${t.instant(`${prefix}.personaldataformintro2.SECTION_COLOR_DARK_BLUE`)}
        </div>
        <div>
          <img src='/assets/img/section-indicator-red.png'>
          ${t.instant(`${prefix}.personaldataformintro2.SECTION_COLOR_RED`)}
        </div>
        <div>
          <img src='/assets/img/section-indicator-blue.png'>
          ${t.instant(`${prefix}.personaldataformintro2.SECTION_COLOR_BLUE`)}
        </div>
        <div>
          <img src='/assets/img/section-indicator-white-blue.png'>
          ${t.instant(`${prefix}.personaldataformintro2.SECTION_COLOR_WHITE_BLUE`)}
        </div>
      </div>
      `,
    },
    {
      id: 'personaldataformcompleteness',
      arrow: true,
      modalOverlayOpeningPadding: 0,
      modalOverlayOpeningRadius: 20,
      attachTo: { element: '.topbar', on: 'bottom' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.personaldataformcompleteness.TITLE`),
      text: `${t.instant(`${prefix}.personaldataformcompleteness.TEXT`)}`,
    },
    {
      id: 'formfieldinfo',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 20,
      attachTo: { element: '.standard-form-field.name-field', on: 'right' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.formfieldinfo.TITLE`),
      text: `${t.instant(`${prefix}.formfieldinfo.TEXT`)}`,
    },
    {
      id: 'formfieldinfo2',
      arrow: true,
      modalOverlayOpeningPadding: 6,
      modalOverlayOpeningRadius: 5,
      attachTo: { element: '.standard-form-field.name-field .mat-form-field-suffix', on: 'right' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.formfieldinfo2.TITLE`),
      text: `${t.instant(`${prefix}.formfieldinfo2.TEXT`)}
      <div class="gif-wrapper">
        <img src='https://storage.googleapis.com/public-bridge-assets/hover-on-tooltip.gif'>
      </div>
      `,
    },
    {
      id: 'countryselectinfo',
      arrow: true,
      modalOverlayOpeningPadding: 6,
      modalOverlayOpeningRadius: 5,
      attachTo: { element: '.country-select-countryOfBirth', on: 'right' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: true,
      title: t.instant(`${prefix}.countryselectinfo.TITLE`),
      text: `${t.instant(`${prefix}.countryselectinfo.TEXT`)}
      <div class="gif-wrapper">
        <img src='https://storage.googleapis.com/public-bridge-assets/country-selector.gif'>
      </div>
      `,
    },
    {
      id: 'selectfromdropdown',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,

      attachTo: { element: '.sex-field', on: 'auto' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: { behavior: 'smooth' },
      title: t.instant(`${prefix}.selectfromdropdown.TITLE`),
      text: `${t.instant(`${prefix}.selectfromdropdown.TEXT`)}
      <div class="gif-wrapper">
        <img src='https://storage.googleapis.com/public-bridge-assets/select-from-list.gif'>
      </div>`,
    },
    {
      id: 'submitforminfo',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 8,
      attachTo: { element: '.save-and-proceed-button', on: 'top-start' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: { behavior: 'smooth' },
      title: t.instant(`${prefix}.submitforminfo.TITLE`),
      text: `${t.instant(`${prefix}.submitforminfo.TEXT`)}`,
    },
    {
      id: 'manualruninstructions',
      arrow: true,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 6,
      attachTo: { element: '.mat-step-text-label .guide-open-button', on: 'left' },
      cancelIcon: { enabled: true },
      highlightClass: 'highlight',
      scrollTo: { behavior: 'smooth' },
      title: t.instant(`${prefix}.manualruninstructions.TITLE`),
      text: `${t.instant(`${prefix}.manualruninstructions.TEXT`)}`,
    },
  ];

  const guideName = t.instant(`${prefix}.GUIDE_NAME`);
  return steps.map((step, currIndex, all) => {
    const formattedButtons: Step.StepOptionsButton[] = [
      { ...buttons.counter, text: `${guideName} ${currIndex + 1}/${all.length}` },
    ];

    if (currIndex === 0) {
      formattedButtons.push({ ...buttons.cancel });
    } else {
      formattedButtons.push({ ...buttons.back });
    }

    if (currIndex < all.length - 1) {
      formattedButtons.push({ ...buttons.next });
    } else {
      formattedButtons.push({ ...buttons.done });
    }

    return { ...step, buttons: formattedButtons };
  });
};
