import { UserProcessStatus } from '@interfaces';
import { Pagination } from './http';

export interface GetForeignersPayload extends Pagination {
  query?: string;
  companyId?: string;
  processId?: string;
  showDeleted?: boolean;
  showInactive?: boolean;
  creatorId?: string;
}

export interface GetEmployeesPayload extends Pagination {
  query?: string;
  roles?: string[];
  showDeleted?: boolean;
  officeId?: string;
  supportedUserOrigins?: USER_ORIGIN[];
}

export interface GetAdminsPayload extends Pagination {
  query?: string;
}
export interface GetOnlineUsersPayload extends Pagination {
  query?: string;
  roles?: string[];
}

export interface GetUsersPayload extends Pagination {
  // array of uuids
  roles?: string[];
  companyId?: string;
  query?: string;
  showDeleted?: boolean;
}

export interface SearchUsersProcessesPayload extends Pagination {
  status?: UserProcessStatus;
  creatorId?: string;
  officeVisitDate?: string; // DD.MM.YYYY | DD.MM.YYYY HH:mm -> used: " userProcess.officeVisitDate ILIKE '[date]%'; ",
  query?: string;
  companyId?: string;
  missingAssistant?: boolean;
}

export enum USER_ORIGIN {
  // FOreigner registered with self-registration form
  SELF_REGISTERED = 'SELF_REGISTERED',

  // Employee created account for foreigner and sent activation-link to provided email address
  INVITED = 'INVITED',
}

export interface SelfRegisterOpts {
  email: string;
  name: string;
  telephoneNumber: string;
  langKey: string;
  hasAcceptedGDPR: boolean;
  referalCode?: string;
}

export interface ReassignForeignersOpts {
  currentEmployeeId: string;
  foreignersIds: string[];
  newEmployeeId: string;
}
