import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { AvailableLanguages } from '@constants';
import { UserProcess, UserProcessStatus } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { VoivodeshipsFacade } from '@state/voivodeships';
import { ReplaySubject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-temp-premit-step2',
  templateUrl: './temp-premit-step2.component.html',
  styleUrls: ['./temp-premit-step2.component.scss'],
})
export class TempPremitStep2Component implements OnInit, AfterViewInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() userProcess: UserProcess;
  @Input() mode: 'foreigner' | 'employee';
  @Output() firstInputShiftTabPressed: EventEmitter<void> = new EventEmitter<void>();
  @Output() lastFieldTabPressed: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmVoivoChange: EventEmitter<void> = new EventEmitter<void>();

  public currentLang: AvailableLanguages;
  public controls: { [key: string]: FormControl } = {};
  public voivodeships$ = this.voivodeshipsFacade.getVoivodeships$();
  public lookingForVoivodeship = false;
  public voivoIsBeeingUsed: boolean;
  public userACKDocumentsReset = false;
  public selectsValues: { [key: string]: { label: string; value: string }[] };

  private previousVoivodeshipId: string;
  private previousZip: string;
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public loadingDistricts$ = this.voivodeshipsFacade.loadingDistricts$;
  public loadingMunicips$ = this.voivodeshipsFacade.loadingMunicips$;
  public loadingCities$ = this.voivodeshipsFacade.loadingCities$;

  public districts$ = this.voivodeshipsFacade.loadedDistricts$.pipe(
    // filter(({ forVoivoId }) => forVoivoId === this.controls?.residenceVoivodeshipDetailsId?.value),
    map(({ forVoivoId, districts }) =>
      forVoivoId === this.controls?.residenceVoivodeshipDetailsId?.value ? districts : []
    ),
    takeUntil(this.destroy$)
  );

  public municips$ = this.voivodeshipsFacade.loadedMunicips$.pipe(
    // filter(({ forDistrictId }) => forDistrictId === this.controls?.residenceDistrictId?.value),
    map(({ forDistrictId, municips }) => (forDistrictId === this.controls?.residenceDistrictId?.value ? municips : [])),
    takeUntil(this.destroy$)
  );

  public cities$ = this.voivodeshipsFacade.loadedCities$.pipe(
    // filter(({ forMunicipId }) => forMunicipId === this.controls?.residenceMunicipalityId?.value),
    map(({ forMunicipId, cities }) => (forMunicipId === this.controls?.residenceMunicipalityId?.value ? cities : [])),
    takeUntil(this.destroy$)
  );

  constructor(
    private readonly voivodeshipsFacade: VoivodeshipsFacade,
    private readonly translateService: TranslateService,
    private readonly confirmationService: ConfirmationModalService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang as AvailableLanguages;
    this.selectsValues = this.translateService.translations[this.currentLang].SELECTS_VALUES;

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang: langKey }) => {
      this.currentLang = langKey as AvailableLanguages;
      this.selectsValues = this.translateService.translations[this.currentLang].SELECTS_VALUES;
    });

    Object.keys(this.formGroup.controls).forEach(fieldName => {
      this.controls[fieldName] = this.formGroup.controls[fieldName] as FormControl;
    });

    // this.handleVoivoChanges(this.userProcess);
    // this.userACKDocumentsReset = false;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.handleVoivoChanges(this.userProcess);
      this.userACKDocumentsReset = false;

      if (this.hasAnyFieldAnyValue(this.formGroup.controls)) {
        this.formGroup.markAllAsTouched();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public firstInputKeydown($event: KeyboardEvent): boolean {
    if (!$event.shiftKey) {
      return true;
    }

    if ($event.key?.toLowerCase() !== 'tab') {
      return true;
    }

    this.firstInputShiftTabPressed.emit();
    return false;
  }

  // control.errors always contains max 1 error at a time
  public getErrorMessage(control: FormControl): string {
    const [validatorName, error]: [string, { errorKey: string; allowedVal: string }] = Object.entries(
      control.errors
    )[0];

    if (validatorName === 'pesel') {
      return this.translateService.instant('FORM-VALIDATION.INVALID-PESEL');
    }

    if (validatorName === 'minlength') {
      return this.translateService.instant('FORM-VALIDATION.VALUE-MUST-BE-MINIMUM-LENGTH', {
        requiredLength: (error as any).requiredLength,
      });
    }

    if (validatorName === 'maxlength') {
      return this.translateService.instant('FORM-VALIDATION.VALUE-MUST-BE-MAXIMUM-LENGTH', {
        requiredLength: (error as any).requiredLength,
      });
    }

    if (validatorName === 'min' || validatorName === 'max') {
      return this.translateService.instant('FORM-VALIDATION.PLEASE-PROVIDE-VAL-BETWEEN-50-AND-280');
    }
    if (validatorName === 'required') {
      return this.translateService.instant(`FORM-VALIDATION.IS_REQUIRED`);
    }
    if (validatorName === 'pattern') {
      return this.translateService.instant(`FORM-VALIDATION.WRONG_FORMAT`);
    }
    if (validatorName === 'wrongPesel') {
      return this.translateService.instant(`FORM-VALIDATION.WRONG_PESEL`);
    }
    if (validatorName === 'notInteger') {
      return this.translateService.instant(`FORM-VALIDATION.NOT_INTEGER`);
    }
    if (validatorName === 'outOfRange') {
      return this.translateService.instant(`FORM-VALIDATION.OUT_OF_RANGE`);
    }

    return this.translateService.instant(`FORM-VALIDATION.${error.errorKey}`, { allowedVal: error.allowedVal });
  }

  public tryFindByZip(zipcode: string): void {
    const zipcodeTrimmed = zipcode.trim().replace(' ', '');
    if (!zipcodeTrimmed.includes('-')) {
      return;
    }
    if (zipcodeTrimmed.length < 6) {
      return;
    }

    this.lookingForVoivodeship = true;
    this.voivodeshipsFacade.getVoivoByZipcodeSuccess$.pipe(take(1)).subscribe(({ voivodeship }) => {
      this.controls.residenceVoivodeshipDetailsId.setValue(voivodeship.id);
      // this.controls.residenceVoivodeshipName.setValue(voivodeship.name);
      this.lookingForVoivodeship = false;
    });

    this.voivodeshipsFacade.getVoivoByZipcodeError$.pipe(take(1)).subscribe(() => {
      // this.controls.residenceVoivodeshipDetailsId.setValue(null);
      // this.controls.residenceVoivodeshipName.setValue(null);
      this.lookingForVoivodeship = false;
    });

    this.voivodeshipsFacade.getVoivoByZip(zipcode);
  }

  public voivoChangeACK(): void {
    this.userACKDocumentsReset = true;
    this.controls.residencePostalCode.enable({ emitEvent: false });
    this.controls.residenceVoivodeshipDetailsId.enable({ emitEvent: false });
  }

  private handleVoivoChanges(userProcess: UserProcess): void {
    const userProcessStatusesWhenVoivoIsUsed = [
      UserProcessStatus.COMPLETING_DOCUMENTS,
      UserProcessStatus.READY_FOR_REVIEW,
      UserProcessStatus.CHANGES_REQUESTED,
      UserProcessStatus.APPROVED,
      UserProcessStatus.REVIEWED_READY_TO_PRINT,
      UserProcessStatus.OFFICE_VISIT_AVAILABILITY_PROVIDED,
      UserProcessStatus.OFFICE_VISIT_SCHEDULED,
      UserProcessStatus.APPLICATION_SUBMITTED,
    ];

    if (this.mode === 'employee') {
      this.handleMOSFields();
    }

    this.voivoIsBeeingUsed = userProcessStatusesWhenVoivoIsUsed.includes(userProcess.status);
    const zipFC = this.controls.residencePostalCode;
    const voivoIdFC = this.controls.residenceVoivodeshipDetailsId;

    // We don't have to do anything if user was not in completing documents
    // (it means that list of documents was not set yet - voivodeship was not required)
    if (this.voivoIsBeeingUsed) {
      this.previousZip = zipFC.value;
      this.previousVoivodeshipId = voivoIdFC.value;
      zipFC.disable();
      voivoIdFC.disable();

      voivoIdFC.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(currVoivoId => {
        if (currVoivoId !== this.previousVoivodeshipId) {
          this.confirmationService
            .open({
              confirmationToTranslate: 'PERSONAL_DATA_FORM.IF-YOU-CHANGE-VOIVO-DOCUMENTS-RESET',
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(res => {
              if (!res) {
                // user don't want to reset documents - bring back old values
                voivoIdFC.setValue(this.previousVoivodeshipId);
                // zipFC.setValue(this.previousZip);
              } else {
                // user agrees to reset documents - set current values as 'old'
                this.previousVoivodeshipId = voivoIdFC.value;
                // this.previousZip = zipFC.value;

                // Notify backend to prepare new list and notify user about requirement to upload docs again
                this.confirmVoivoChange.emit();
              }
            });
        }
      });
    }

    this.controls.residencePostalCode.valueChanges
      .pipe(
        filter((zipcode: string) => !zipcode.match(/[^\d]/)),
        filter((zipcode: string) => !!(zipcode && zipcode.length === 5)),
        map((zipcode: string) => `${zipcode.slice(0, 2)}-${zipcode.slice(2)}`),
        takeUntil(this.destroy$)
      )
      .subscribe((zipcode: string) => {
        this.tryFindByZip(zipcode);
      });
  }

  private handleMOSFields(): void {
    const voivoIdFC = this.controls.residenceVoivodeshipDetailsId;
    const districtFC = this.controls.residenceDistrictId;
    const municipFC = this.controls.residenceMunicipalityId;
    const cityListedFC = this.controls.residenceCityListedId;

    if (voivoIdFC.value) {
      this.voivodeshipsFacade.getDistricts({
        voivoId: voivoIdFC.value,
      });
    }
    if (districtFC.value) {
      this.voivodeshipsFacade.getMunicips({
        voivoId: voivoIdFC.value,
        districtId: districtFC.value,
      });
    }
    if (municipFC.value) {
      this.voivodeshipsFacade.getCities({
        voivoId: voivoIdFC.value,
        districtId: districtFC.value,
        municipId: municipFC.value,
      });
    }

    voivoIdFC.valueChanges
      .pipe(
        filter(voivoId => !!voivoId && voivoId !== this.userProcess.personalDetails.residenceVoivodeshipDetailsId),
        takeUntil(this.destroy$)
      )
      .subscribe(currVoivoId => {
        districtFC.setValue(null);
        municipFC.setValue(null);
        cityListedFC.setValue(null);

        this.voivodeshipsFacade.getDistricts({ voivoId: currVoivoId });
      });

    districtFC.valueChanges
      .pipe(
        filter(districtId => !!districtId && districtId !== this.userProcess.personalDetails.residenceDistrictId),
        takeUntil(this.destroy$)
      )
      .subscribe(currDistrictId => {
        municipFC.setValue(null);
        cityListedFC.setValue(null);

        this.voivodeshipsFacade.getMunicips({
          voivoId: voivoIdFC.value,
          districtId: currDistrictId,
        });
      });

    municipFC.valueChanges
      .pipe(
        filter(municipId => !!municipId && municipId !== this.userProcess.personalDetails.residenceMunicipalityId),
        takeUntil(this.destroy$)
      )
      .subscribe(currMunicipId => {
        cityListedFC.setValue(null);

        this.voivodeshipsFacade.getCities({
          voivoId: voivoIdFC.value,
          districtId: districtFC.value,
          municipId: currMunicipId,
        });
      });
  }

  private hasAnyFieldAnyValue(controls: { [key: string]: AbstractControl }): boolean {
    return Object.keys(controls).some(controlName => {
      const val = controls[controlName].value;

      if (Array.isArray(val) && val.length > 0) {
        return true;
      }

      return val !== '' && val !== undefined && val !== null;
    });
  }
}
