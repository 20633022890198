import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { fullDateFormat, patterns } from '@constants';
import { isBoolean } from 'lodash-es';
import * as moment from 'moment-timezone';

export enum DateFormat {
  'DD.MM.YYYY' = 'DD.MM.YYYY',
  'MM.YYYY' = 'MM.YYYY',
  'YYYY' = 'YYYY',
}

const validDateFormats: { [key: string]: { format: DateFormat; errorKey: string } } = {
  [DateFormat['DD.MM.YYYY']]: { format: DateFormat['DD.MM.YYYY'], errorKey: 'invalidDateDDMMYYYY' },
  [DateFormat['MM.YYYY']]: { format: DateFormat['MM.YYYY'], errorKey: 'invalidDateMMYYYY' },
  [DateFormat['YYYY']]: { format: DateFormat['YYYY'], errorKey: 'invalidDateYYYY' },
};

interface VisitDateValidationOpts {
  format?: DateFormat;
}

interface FutureDateValidationOpts {
  specificDate?: string;
  yearsAhead?: number;
}

interface PastDateValidationOpts {
  specificDate?: string;
  yearsBack?: number;
}

interface IntegerValidationOpts {
  min?: number;
  max?: number;
}

export const customDateValidator = (fieldControl: AbstractControl): ValidationErrors | null => {
  let val = fieldControl.value;
  // we don't care about empty values = required validator is for it
  if (!val) {
    return null;
  }

  if (val._isAMomentObject) {
    val = val.format(fullDateFormat);
  }

  if (!val.match(patterns.DateValidator)) {
    return { pattern: { errorKey: 'INVALID-DATE' } };
  }

  return null;
};

export const customVisitDateValidator = (fieldControl: AbstractControl): ValidationErrors | null => {
  const val = fieldControl.value;
  // we don't care about empty values = required validator is for it
  if (!val) {
    return null;
  }

  const valAsStringTrimmed = val.toString().trim().toLowerCase();

  // only these lengths are valid
  if (![4, 7, 10].includes(valAsStringTrimmed.length)) {
    return { pattern: { errorKey: 'INVALID-DATE' } };
  }

  if (valAsStringTrimmed.length === 4 && !val.match(/^[0-9]+$/)) {
    return { pattern: { errorKey: 'INVALID-DATE' } };
  }

  if (valAsStringTrimmed.length === 7 && !val.match(/^([0-9][0-9])\.([0-9][0-9][0-9][0-9])+$/)) {
    return { pattern: { errorKey: 'INVALID-DATE' } };
  }

  if (valAsStringTrimmed.length === 10 && !val.match(/^([0-9][0-9])\.([0-9][0-9])\.([0-9][0-9][0-9][0-9])+$/)) {
    return { pattern: { errorKey: 'INVALID-DATE' } };
  }

  return null;
};

export function customDateLengthValidator(opts: VisitDateValidationOpts): ValidatorFn {
  return (fieldControl: AbstractControl): ValidationErrors | null => {
    const val = fieldControl.value;
    if (!val) {
      return null;
    }

    let dateOpts = validDateFormats[DateFormat['DD.MM.YYYY']];

    if (opts?.format) {
      dateOpts = validDateFormats[DateFormat[opts.format]];
    }

    if (!moment(val, dateOpts.format, true).isValid()) {
      return { [dateOpts.errorKey]: true };
    }

    return null;
  };
}

export function forbiddenDateInPast(opts?: PastDateValidationOpts): ValidatorFn {
  return (fieldControl: AbstractControl): ValidationErrors | null => {
    const val = fieldControl.value;
    if (!val) {
      return null;
    }

    let minimumDate: moment.Moment = moment();
    let inputDate: moment.Moment;

    if (moment.isMoment(val)) {
      inputDate = val;
    } else {
      const parts = val.split('.');

      if (parts.length === 3) {
        inputDate = moment(val, 'DD.MM.YYYY', true);
      }
      if (parts.length === 2) {
        inputDate = moment(val, 'MM.YYYY', true);
      }
      if (parts.length === 1) {
        inputDate = moment(val, 'YYYY', true);
      }

      if (parts.length !== 1 && parts.length !== 2 && parts.length !== 3) {
        return { invalidFormat: true };
      }

      if (!inputDate.isValid()) {
        return { invalidDate: true };
      }
    }

    if (opts?.specificDate) {
      minimumDate = moment(opts.specificDate, 'DD.MM.YYYY', true);
      if (!minimumDate.isValid()) {
        throw Error('Invalid specific date provided for forbiddenDateInPast');
      }
    }
    if (opts?.yearsBack) {
      minimumDate = moment().subtract(opts.yearsBack, 'years');
    }

    if (inputDate.isBefore(minimumDate)) {
      if (!opts?.specificDate && !opts?.yearsBack) {
        return { providedDateMustBeInTheFuture: true };
      } else {
        return { providedDateBeforeMinimumDate: true };
      }
    }

    return null;
  };
}

export function forbiddenFutureDate(opts?: FutureDateValidationOpts): ValidatorFn {
  return (fieldControl: AbstractControl): ValidationErrors | null => {
    const val = fieldControl.value;
    if (!val) {
      return null;
    }

    let maximumDate: moment.Moment = moment();
    let inputDate: moment.Moment;

    if (moment.isMoment(val)) {
      inputDate = val;
    } else {
      const parts = val.split('.');

      if (parts.length === 3) {
        inputDate = moment(val, 'DD.MM.YYYY', true);
      }
      if (parts.length === 2) {
        inputDate = moment(val, 'MM.YYYY', true);
      }
      if (parts.length === 1) {
        inputDate = moment(val, 'YYYY', true);
      }

      if (parts.length !== 1 && parts.length !== 2 && parts.length !== 3) {
        return { invalidFormat: true };
      }

      if (!inputDate.isValid()) {
        return { invalidDate: true };
      }
    }

    if (opts?.specificDate) {
      maximumDate = moment(opts.specificDate, 'DD.MM.YYYY', true);
      if (!maximumDate.isValid()) {
        throw Error('Invalid specific date provided for forbiddenFutureDate');
      }
    }
    if (opts?.yearsAhead) {
      maximumDate = moment().add(opts.yearsAhead, 'years');
    }

    if (opts?.specificDate && inputDate.isSame(maximumDate, 'day')) {
      return null;
    }

    if (inputDate.isAfter(maximumDate)) {
      if (!opts?.specificDate && !opts?.yearsAhead) {
        return { providedDateMustBeInThePast: true };
      } else {
        return { providedDateAfterMaximumDate: true };
      }
    }

    return null;
  };
}

export function customDateComparisonValidator(entryFieldName: string, leaveFieldName: string): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const formGroup = group as FormGroup;
    const entryDateControl = formGroup.get(entryFieldName);
    const leaveDateControl = formGroup.get(leaveFieldName);

    if (!entryDateControl || !leaveDateControl) {
      return null;
    }

    const entryDate = entryDateControl.value;
    const leaveDate = leaveDateControl.value;

    if (!entryDate || !leaveDate) {
      return null;
    }

    const parseDate = (dateStr: string): Date | null => {
      const parts = dateStr.split('.').map(Number);

      if (parts.length === 3) {
        const [day, month, year] = parts;
        return new Date(year, month - 1, day);
      } else if (parts.length === 2) {
        const [month, year] = parts;
        return new Date(year, month - 1, 1);
      } else if (parts.length === 1) {
        const [year] = parts;
        return new Date(year, 0, 1);
      }

      return null;
    };

    const entryDateParsed = parseDate(entryDate);
    const leaveDateParsed = parseDate(leaveDate);

    if (entryDateParsed && leaveDateParsed && leaveDateParsed < entryDateParsed) {
      entryDateControl.setErrors({ comparisonInvalid: true });
      leaveDateControl.setErrors({ comparisonInvalid: true });
      entryDateControl.markAsTouched();
      leaveDateControl.markAsTouched();
      return { invalidDateComparison: true };
    }

    if (entryDateControl.hasError('comparisonInvalid')) {
      const entryDateErrors = { ...entryDateControl.errors };
      delete entryDateErrors['comparisonInvalid'];
      entryDateControl.setErrors(Object.keys(entryDateErrors).length ? entryDateErrors : null);
    }

    if (leaveDateControl.hasError('comparisonInvalid')) {
      const leaveDateErrors = { ...leaveDateControl.errors };
      delete leaveDateErrors['comparisonInvalid'];
      leaveDateControl.setErrors(Object.keys(leaveDateErrors).length ? leaveDateErrors : null);
    }

    return null;
  };
}

export function isIntegerValidator(opts?: IntegerValidationOpts): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value === null || value === undefined) {
      return null;
    }

    if (!Number.isInteger(value)) {
      return { notInteger: true };
    }

    if (opts?.min !== undefined && value < opts.min) {
      return { outOfRange: true };
    }

    if (opts?.max !== undefined && value > opts.max) {
      return { outOfRange: true };
    }

    return null;
  };
}

export const isBooleanValidator = (fieldControl: AbstractControl): ValidationErrors | null => {
  const val = fieldControl.value;

  // we don't care about empty values = required validator is for it
  if (val === null || val === '' || val === undefined) {
    return null;
  }
  if (!isBoolean(val)) {
    return { notBoolean: true };
  }
  return null;
};

export const isNumericValidator = (fieldControl: AbstractControl): ValidationErrors | null => {
  const val = fieldControl.value;

  // We don't care about empty values; required validator should handle them
  if (val === null || val === '' || val === undefined) {
    return null;
  }
  if (isNaN(val)) {
    return [{ errorKey: 'IS_INVALID' }];
  }
  return null;
};

export const isPeselValid = (fieldValue: string) => {
  if (fieldValue?.length !== 11) {
    return false;
  }

  let year = parseInt(fieldValue.substring(0, 2), 10);
  let month = parseInt(fieldValue.substring(2, 4), 10) - 1;
  const day = parseInt(fieldValue.substring(4, 6), 10);
  // For people born since 2000 year we're adding values to the `month` to have it working.
  // This is the PESEL standard procedure.
  // So if month is between specific values we have to modify year and month
  if (month < 20) {
    year += 1900;
  }
  if (month >= 20 && month < 40) {
    year += 2000;
    month -= 20;
  }
  if (month >= 40 && month < 60) {
    year += 2100;
    month -= 40;
  }
  if (month >= 60 && month < 80) {
    year += 2200;
    month -= 60;
  }
  if (month >= 80) {
    year += 1800;
    month -= 80;
  }

  const dateOfBirth = new Date();
  dateOfBirth.setFullYear(year, month, day);

  const controlVals = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
  let controlSum = 0;

  controlVals.forEach((controlVal, controlValIndex) => {
    const calculatedVal = parseInt(fieldValue.substring(controlValIndex, controlValIndex + 1), 10) * controlVal;
    controlSum += calculatedVal;
  });

  const controlSumMod = controlSum % 10;
  const controlDigit = parseInt(fieldValue.substring(10, 11), 10);
  const isCorrect = controlSumMod === controlDigit;
  return dateOfBirth && isCorrect;
};
