import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as backOfficeActions from '@state/back-office/back-office.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { parseError } from '@state/errors.parser';
import { of } from 'rxjs';
import { BackOfficeHttpService } from '@core/http/back-office.http.service';
import { GetFileService } from '@shared/get-file/get-file.service';

@Injectable()
export class BackOfficeEffects {
  constructor(
    private actions$: Actions,
    private http: BackOfficeHttpService,
    private readonly getFileService: GetFileService
  ) {}

  getRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.getRoles),
      switchMap(() => {
        return this.http.getRoles().pipe(
          map(roles => {
            return backOfficeActions.getRolesSuccess({ roles });
          }),
          catchError(error => {
            const errorMessage = parseError(error, backOfficeActions.getRoles.type);
            return of(backOfficeActions.getRolesError({ errorMessage }));
          })
        );
      })
    )
  );

  getCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.getCompanies),
      switchMap(() => {
        return this.http.getCompanies().pipe(
          map(companies => {
            return backOfficeActions.getCompaniesSuccess({ companies });
          }),
          catchError(error => {
            const errorMessage = parseError(error, backOfficeActions.getCompanies.type);
            return of(backOfficeActions.getCompaniesError({ errorMessage }));
          })
        );
      })
    )
  );

  getEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.getEmployees),
      switchMap(() => {
        return this.http.getEmployees().pipe(
          map(employees => {
            return backOfficeActions.getEmployeesSuccess({ employees });
          }),
          catchError(error => {
            const errorMessage = parseError(error, backOfficeActions.getEmployees.type);
            return of(backOfficeActions.getEmployeesError({ errorMessage }));
          })
        );
      })
    )
  );

  // ############
  // Users
  // ############

  getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.getUsers),
      switchMap(({ payload }) => {
        const { all, count, ...rest } = payload;
        return this.http.getUsers(rest).pipe(
          map(users => {
            return backOfficeActions.getUsersSuccess({ users: users });
          }),
          catchError(error => {
            const errorUsersMessage = parseError(error, backOfficeActions.getUsers.type);
            return of(backOfficeActions.getUsersError({ errorUsersMessage }));
          })
        );
      })
    )
  );

  usersActivateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.usersActivateAccount),
      switchMap(({ payload }) => {
        const { userID, password, passwordConfirm } = payload;
        return this.http.usersActivateAccount({ userID, password, passwordConfirm }).pipe(
          map(({ activatedAccountID }) => {
            return backOfficeActions.usersActivateAccountSuccess({ activatedAccountID });
          }),
          catchError(error => {
            const errorUsersMessage = parseError(error, backOfficeActions.usersActivateAccount.type);
            return of(backOfficeActions.usersActivateAccountError({ errorUsersMessage }));
          })
        );
      })
    )
  );

  usersDeactivateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.usersDeactivateAccount),
      switchMap(({ payload }) => {
        const { userID } = payload;
        return this.http.usersDeactivateAccount({ userID }).pipe(
          map(({ deactivatedAccountID }) => {
            return backOfficeActions.usersDeactivateAccountSuccess({ deactivatedAccountID });
          }),
          catchError(error => {
            const errorUsersMessage = parseError(error, backOfficeActions.usersDeactivateAccount.type);
            return of(backOfficeActions.usersDeactivateAccountError({ errorUsersMessage }));
          })
        );
      })
    )
  );

  usersRestoreAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.usersRestoreAccount),
      switchMap(({ payload }) => {
        const { userID } = payload;
        return this.http.usersRestoreAccount({ userID }).pipe(
          map(({ restoredAccountID }) => {
            return backOfficeActions.usersRestoreAccountSuccess({ restoredAccountID });
          }),
          catchError(error => {
            const errorUsersMessage = parseError(error, backOfficeActions.usersRestoreAccount.type);
            return of(backOfficeActions.usersRestoreAccountError({ errorUsersMessage }));
          })
        );
      })
    )
  );

  usersChangePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.usersChangePassword),
      switchMap(({ payload }) => {
        const { userID, mode, password, passwordConfirm } = payload;
        return this.http.usersChangePassword({ userID, mode, password, passwordConfirm }).pipe(
          map(BackOfficeChangePasswordReturn => {
            return backOfficeActions.usersChangePasswordSuccess(BackOfficeChangePasswordReturn);
          }),
          catchError(error => {
            const errorUsersMessage = parseError(error, backOfficeActions.usersChangePassword.type);
            return of(backOfficeActions.usersChangePasswordError({ errorUsersMessage }));
          })
        );
      })
    )
  );

  usersSoftDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.usersSoftDelete),
      switchMap(({ payload }) => {
        const { usersID } = payload;
        return this.http.usersSoftDelete({ usersID }).pipe(
          map(({ deletedUsersID }) => {
            return backOfficeActions.usersSoftDeleteSuccess({ deletedUsersID });
          }),
          catchError(error => {
            const errorUsersMessage = parseError(error, backOfficeActions.usersSoftDelete.type);
            return of(backOfficeActions.usersSoftDeleteError({ errorUsersMessage }));
          })
        );
      })
    )
  );

  usersPermDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.usersPermDelete),
      switchMap(({ payload }) => {
        const { usersID } = payload;
        return this.http.usersPermDelete({ usersID }).pipe(
          map(({ deletedUsersID }) => {
            return backOfficeActions.usersPermDeleteSuccess({ deletedUsersID });
          }),
          catchError(error => {
            const errorUsersMessage = parseError(error, backOfficeActions.usersPermDelete.type);
            return of(backOfficeActions.usersPermDeleteError({ errorUsersMessage }));
          })
        );
      })
    )
  );

  // ############
  // Transactions
  // ############

  getTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.getTransactions),
      switchMap(({ payload }) => {
        const { all, count, ...rest } = payload;
        return this.http.getTransactions(rest).pipe(
          map(transactions => {
            return backOfficeActions.getTransactionsSuccess({ transactions: transactions });
          }),
          catchError(error => {
            const errorTransactionsMessage = parseError(error, backOfficeActions.getTransactions.type);
            return of(backOfficeActions.getTransactionsError({ errorTransactionsMessage }));
          })
        );
      })
    )
  );

  transactionsDownloadPurchaseDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.transactionsDownloadPurchaseDocument),
      switchMap(({ transactionID }) => {
        return this.http.downloadPurchaseDocument({ transactionID }).pipe(
          map(response => {
            if (this.getFileService.getFile(response)) {
              return backOfficeActions.transactionsDownloadPurchaseDocumentSuccess();
            } else {
              return backOfficeActions.transactionsDownloadPurchaseDocumentError({
                errorTransactionsMessage: 'DOWNLOADING ERROR',
              });
            }
          }),
          catchError(error => {
            const errorTransactionsMessage = parseError(
              error,
              backOfficeActions.transactionsDownloadPurchaseDocument.type
            );
            return of(backOfficeActions.transactionsDownloadPurchaseDocumentError({ errorTransactionsMessage }));
          })
        );
      })
    )
  );

  transactionsGenerateReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.transactionsGenerateReport),
      switchMap(({ payload }) => {
        return this.http.generateTransactionsReport(payload).pipe(
          map(response => {
            if (this.getFileService.getFile(response)) {
              return backOfficeActions.transactionsGenerateReportSuccess();
            } else {
              return backOfficeActions.transactionsGenerateReportError({
                errorTransactionsMessage: 'DOWNLOADING ERROR',
              });
            }
          }),
          catchError(error => {
            const errorTransactionsMessage = parseError(error, backOfficeActions.transactionsGenerateReport.type);
            return of(backOfficeActions.transactionsGenerateReportError({ errorTransactionsMessage }));
          })
        );
      })
    )
  );

  // ############
  // Applications
  // ############

  getApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.getApplications),
      switchMap(({ payload }) => {
        const { all, count, ...rest } = payload;
        return this.http.getApplications(rest).pipe(
          map(applications => {
            return backOfficeActions.getApplicationsSuccess({ applications });
          }),
          catchError(error => {
            const errorApplicationsMessage = parseError(error, backOfficeActions.getApplications.type);
            return of(backOfficeActions.getApplicationsError({ errorApplicationsMessage }));
          })
        );
      })
    )
  );

  applicationsGenerateReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.applicationsGenerateReport),
      switchMap(({ payload }) => {
        return this.http.generateApplicationsReport(payload).pipe(
          map(response => {
            if (this.getFileService.getFile(response)) {
              return backOfficeActions.applicationsGenerateReportSuccess();
            } else {
              return backOfficeActions.applicationsGenerateReportError({
                errorApplicationsMessage: 'DOWNLOADING ERROR',
              });
            }
          }),
          catchError(error => {
            const errorApplicationsMessage = parseError(error, backOfficeActions.applicationsGenerateReport.type);
            return of(backOfficeActions.applicationsGenerateReportError({ errorApplicationsMessage }));
          })
        );
      })
    )
  );

  applicationsSoftDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.applicationsSoftDelete),
      switchMap(({ payload }) => {
        return this.http.applicationsSoftDelete(payload).pipe(
          map(({ deletedApplicationsID }) => {
            return backOfficeActions.applicationsSoftDeleteSuccess({ deletedApplicationsID });
          }),
          catchError(error => {
            const errorApplicationsMessage = parseError(error, backOfficeActions.applicationsSoftDelete.type);
            return of(backOfficeActions.applicationsSoftDeleteError({ errorApplicationsMessage }));
          })
        );
      })
    )
  );

  applicationsRestore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backOfficeActions.applicationsRestore),
      switchMap(({ payload }) => {
        return this.http.applicationsRestore(payload).pipe(
          map(({ deletedApplicationID }) => {
            return backOfficeActions.applicationsRestoreSuccess({ deletedApplicationID });
          }),
          catchError(error => {
            const errorApplicationsMessage = parseError(error, backOfficeActions.applicationsRestore.type);
            return of(backOfficeActions.applicationsRestoreeError({ errorApplicationsMessage }));
          })
        );
      })
    )
  );
}
