import { Injectable } from '@angular/core';
import { FontSize } from '@constants';
import { Language } from '@interfaces';

export interface UserTableSettings {
  pageSize: number;
}

export enum TableName {
  APPLICATIONS = 'APP',
  FOREIGNERS = 'FOR',
  COMPANIES = 'COMP',
  VOIVODESHIPS = 'VOIV',
  EMPLOYEES = 'EMP',
  ADMINS = 'ADS',
  ONLINE_USERS = 'OUs',
  COMPANY_MANAGERS = 'CMP_MG',
  EMPLOYEES_OF_MANAGER = 'EMP_F_MG',
  PROCESSES_OF_USER = 'PC_OF_UR',
  BACK_OFFICE_USERS = 'BO_UR',
  BACK_OFFICE_TRANSACTIONS = 'BO_TR',
  BACK_OFFICE_APPLICATIONS = 'BO_AP',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private SETTINGS_KEY = 'BR-RESU-G';

  constructor() {}

  public setFirstLogin(): void {
    const key = `${this.SETTINGS_KEY}_F_L_A`;
    this.setItem(key, 'true');
  }

  public getFirstLogin(): boolean {
    const KEY = `${this.SETTINGS_KEY}_F_L_A`;
    return this.parseAsBool(this.getItem(KEY));
  }

  public setTableSettings(tableName: TableName, settings: UserTableSettings): void {
    const settingsString = JSON.stringify(settings);
    localStorage.setItem(`${this.SETTINGS_KEY}_T-${tableName}`, settingsString);
  }

  public getTableSettings(tableName: TableName): UserTableSettings {
    const settingsString = localStorage.getItem(`${this.SETTINGS_KEY}_T-${tableName}`);
    return JSON.parse(settingsString);
  }

  public setFontSize(fontSize: FontSize): void {
    localStorage.setItem('F', fontSize);
  }

  public getFontSize(): string | null {
    return localStorage.getItem('F') || null;
  }

  public setUserLang(userLang: Language): void {
    try {
      const stringified = JSON.stringify(userLang);
      localStorage.setItem('U_L', stringified);
      return;
    } catch (err) {
      console.error('Trying to store invalid language object inside local storage');
      return;
    }
  }

  public getUserLang(): Language | null {
    const asString = localStorage.getItem('U_L');
    if (!asString?.length) {
      return null;
    }

    try {
      return JSON.parse(asString) as Language;
    } catch (err) {
      console.error('Invalid language object stored in LocalStorage');
      return null;
    }
  }

  public setChatHintSeen(val: boolean): void {
    this.setItem('C_H_S', val.toString());
  }

  public getChatHintSeen(): boolean | null {
    const KEY = `C_H_S`;
    return this.parseAsBool(this.getItem(KEY));
  }

  public getGeneralChatHintSeen(): boolean | null {
    const KEY = `G_C_H_S`;
    return this.parseAsBool(this.getItem(KEY));
  }

  public setGeneralChatHintSeen(val: boolean): void {
    this.setItem('G_C_H_S', val.toString());
  }

  public getPersonalDetailsFormStepSeen(stepNumber: number): boolean | null {
    const KEY = `PDF_SS_${stepNumber}`;
    return this.parseAsBool(this.getItem(KEY));
  }

  public setPersonalDetailsFormStepSeen(stepNumber: number, val: boolean): void {
    const KEY = `PDF_SS_${stepNumber}`;
    this.setItem(KEY, val.toString());
  }

  public getDocumentsListSeen(): boolean | null {
    const KEY = `DL_S`;
    return this.parseAsBool(this.getItem(KEY));
  }

  public setDocumentsListSeen(val: boolean): void {
    const KEY = `DL_S`;
    this.setItem(KEY, val.toString());
  }

  public getVisitDatePickerSeen(): boolean | null {
    const KEY = `V_D_P_S`;
    return this.parseAsBool(this.getItem(KEY));
  }

  public setVisitDatePickerSeen(val: boolean): void {
    const KEY = `V_D_P_S`;
    this.setItem(KEY, val.toString());
  }

  public getConsultantTableSeen(): boolean | null {
    const KEY = `F_C_T_S`;
    return this.parseAsBool(this.getItem(KEY));
  }
  public setConsultantTableSeen(val: boolean): void {
    const KEY = `F_C_T_S`;
    this.setItem(KEY, val.toString());
  }

  public getPostModeExplanationModalSeen(userProcessId: string): boolean {
    const KEY = `MDE_CHD_FUPS_${this.hashText(userProcessId.split('-').join())}`;
    return this.parseAsBool(this.getItem(KEY));
  }

  public setPostModeExplanationModalSeen(userProcessId: string, val: boolean): void {
    const KEY = `MDE_CHD_FUPS_${this.hashText(userProcessId.split('-').join())}`;
    this.setItem(KEY, val.toString());
  }

  public getSeenPersonalDataForm(userProcessId?: string): boolean {
    const KEY = userProcessId ? 'P_D_F_A_S_' + this.hashText(userProcessId.split('-').join()) : 'P_D_F_A_S';
    return this.parseAsBool(this.getItem(KEY));
  }
  public setSeenPersonalDataForm(userProcessId?: string, val?: boolean): void {
    const KEY = userProcessId && val ? 'P_D_F_A_S_' + this.hashText(userProcessId.split('-').join()) : 'P_D_F_A_S';
    this.setItem(KEY, 'true');
  }

  public setCookieBanner(val: boolean): void {
    const KEY = `FGD_CB_E`;
    this.setItem(KEY, val.toString());
  }

  public getCookieBanner(): boolean {
    const KEY = `FGD_CB_E`;
    return this.parseAsBool(this.getItem(KEY));
  }

  public getSummaryInstructionsSeen(userProcessId: string): boolean {
    const KEY = `${this.SETTINGS_KEY}_AIM_SINSTR_${this.hashText(userProcessId.split('-').join())}`;
    return this.parseAsBool(this.getItem(KEY));
  }

  public setSummaryInstructionsSeen(userProcessId: string, val: boolean): void {
    const KEY = `${this.SETTINGS_KEY}_AIM_SINSTR_${this.hashText(userProcessId.split('-').join())}`;
    this.setItem(KEY, val.toString());
  }

  public setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public getItem(key: string): string | null {
    return localStorage.getItem(key);
  }
  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  private parseAsBool(valueReturnedFromLs: string): boolean | null {
    if (valueReturnedFromLs && valueReturnedFromLs !== 'true' && valueReturnedFromLs !== 'false') {
      console.error(`Invalid value: '${valueReturnedFromLs}' stored in LS`);
      return null;
    }
    return valueReturnedFromLs === 'true';
  }

  /**
   * Returns a hash code from a string
   * @param  {String} str The string to hash.
   * @return {Number}    A 32bit integer
   * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
   */
  private hashText(text: string): number {
    let hash = 0;
    for (let i = 0, len = text.length; i < len; i++) {
      let chr = text.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
}
